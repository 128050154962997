var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.queryParams }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "关键字搜索：" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.queryParams.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "keyword", $$v)
                      },
                      expression: "queryParams.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.requestData(1)
                        }
                      }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleEdit(-100, null)
                        }
                      }
                    },
                    [_vm._v("添加推广")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, border: "", stripe: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "名称" } }),
          _c("el-table-column", { attrs: { prop: "url", label: "链接" } }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope.$index, scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.$index, scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.queryParams.page,
          "page-size": _vm.queryParams.pageSize,
          layout: "total, prev, pager, next",
          total: _vm.total
        },
        on: {
          "current-change": function($event) {
            return _vm.requestData(null)
          },
          "update:currentPage": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:current-page": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          }
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "增加/修改推广",
            visible: _vm.dialogNewVisible,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogNewVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "newForm",
              attrs: {
                model: _vm.newForm,
                rules: _vm.rules,
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "name", $$v)
                      },
                      expression: "newForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.newForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "type", $$v)
                        },
                        expression: "newForm.type"
                      }
                    },
                    _vm._l(_vm.typeMap, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "指定角色", prop: "role_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.newForm.role_id,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "role_id", $$v)
                        },
                        expression: "newForm.role_id"
                      }
                    },
                    [
                      _c("el-option", { attrs: { value: 0, label: "无" } }),
                      _vm._l(_vm.roleMap, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "帮助中心链接", prop: "help_url" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newForm.help_url,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "help_url", $$v)
                      },
                      expression: "newForm.help_url"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: {
                    click: function($event) {
                      return _vm.handleSave("newForm")
                    }
                  }
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogNewVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }