import service from '@/utils/request';
export var editPopularize = function editPopularize(data) {
  return service({
    url: "/popularize/edit",
    method: 'post',
    data: data
  });
};
export var deletePopularize = function deletePopularize(data) {
  return service({
    url: "/popularize/delete",
    method: 'delete',
    data: data
  });
};
export var getPopularizeList = function getPopularizeList(params) {
  return service({
    url: "/popularize/list",
    method: 'get',
    params: params
  });
};