import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { deletePopularize, editPopularize, getPopularizeList } from '@/api/popularize';
import { getRoleList } from '@/api/permission';
export default {
  name: "popularize",
  data: function data() {
    return {
      data: [],
      queryParams: {
        keyword: "",
        page: 1,
        pageSize: 20
      },
      loading: false,
      total: 0,
      typeMap: [{
        label: "注册",
        value: 1
      }],
      roleMap: [],
      formLabelWidth: '120px',
      dialogNewVisible: false,
      submitting: false,
      editIndex: '',
      newForm: {
        id: 0,
        type: 1,
        name: "",
        role_id: 0,
        help_url: ""
      },
      rules: {
        type: [{
          required: true,
          message: '类型 必填'
        }],
        name: [{
          required: true,
          message: '名称 必填'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.requestData(1);
    getRoleList({
      page: -1,
      pageSize: -1
    }).then(function (res) {
      if (res.code === 0) {
        _this.roleMap = res.data.list;
      }
    });
  },
  methods: {
    requestData: function requestData(page) {
      var _this2 = this;

      if (page !== null) {
        this.queryParams.page = page;
      }

      this.loading = true;
      getPopularizeList(_objectSpread({}, this.queryParams)).then(function (res) {
        _this2.loading = false;

        if (res.code === 0) {
          _this2.data = res.data.list;
          _this2.total = res.data.total;
        }
      });
    },
    handleDelete: function handleDelete(index, row) {
      var _this3 = this;

      this.$confirm('您确定要删除这项数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deletePopularize({
          id: row.id
        }).then(function (res) {
          if (res.code === 0) {
            _this3.$message.success('删除成功');

            _this3.data.splice(index, 1);
          }
        });
      }).catch(function () {
        _this3.$message.info('已取消删除');
      });
    },
    handleEdit: function handleEdit(index, row) {
      this.editIndex = '';

      if (row) {
        this.newForm = {
          id: row.id,
          type: row.type,
          name: row.name,
          role_id: row.role_id,
          help_url: row.help_url
        };
        this.editIndex = index;
      } else {
        this.newForm = {
          id: 0,
          type: 1,
          name: "",
          role_id: 0,
          help_url: ""
        };
      }

      this.dialogNewVisible = true;
    },
    handleSave: function handleSave(formName) {
      var _this4 = this;

      this.submitting = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var form = _objectSpread({}, _this4.newForm);

          editPopularize(form).then(function (res) {
            _this4.submitting = false;

            if (res.code === 0) {
              _this4.$message.success('编辑成功');

              _this4.dialogNewVisible = false;

              if (_this4.editIndex === "") {
                _this4.data.unshift(res.data);
              } else {
                var arr = _this4.data.slice();

                arr[_this4.editIndex] = res.data;
                _this4.data = arr;
              }
            }
          });
        } else {
          _this4.submitting = false;
        }
      });
    }
  }
};